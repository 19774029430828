<template>

    <div v-if="ptzStatus !== null">
        <div :style="!device.isLive(sourceId) === true ? 'opacity: .25; pointer-events: none;' : ''">
            <!--Move Camera Endpoints-->
            <div class="row" style="text-align: center; margin: 0;">
                <div v-if="ptzStatus.extendedSpaces.length > 0 && ptzStatus.extendedSpaces.find(_ => _.ptzSpaceType === 2 && _.ptzTranslationType === 2) !== undefined && supportsPTZ()"
                    class="d-flex w-100 justify-content-center">
                    <div style="margin-right: 5px;">Click PTZ</div>
                    <b-form-checkbox class="custom-switch" @change="setClickPTZ" :disabled="checkIsPlayingBack()" v-model="ptzBool"/>
                </div>
                <!-- Camera Move Directions -->
                <div v-if="supportsPTZ()" style="text-align: center; margin: auto;">
                    <h4 style="margin-top: 10px;">Move Camera</h4>
                    <div style="text-align: center;">
                        <fa-icon v-if="ptzStatus.panTiltYLimitMax !== 0" :icon="['fas', 'arrow-up']" size="2x"
                                 @click="panTilt(0)" style="cursor: pointer;"/>
                        <br>
                        <fa-icon v-if="ptzStatus.panTiltXLimitMin !== 0" :icon="['fas', 'arrow-left']" size="2x"
                                 @click="panTilt(1)" style="margin-right: 12px; cursor: pointer;"/>
                        <fa-icon v-if="ptzStatus.panTiltXLimitMax !== 0" :icon="['fas', 'arrow-right']" size="2x"
                                 @click="panTilt(2)" style="margin-left: 12px; cursor: pointer;"/>
                        <br>
                        <fa-icon v-if="ptzStatus.panTiltYLimitMin !== 0" :icon="['fas', 'arrow-down']" size="2x"
                                 @click="panTilt(3)" style="cursor: pointer;"/>
                    </div>
                </div>
            </div>
            <!-- Movement and Zoom -->
            <div
                v-if="ptzStatus.panTiltXLimitMax !== 0"
                class="d-flex flex-column w-100 align-items-center" style="padding: 0 10px;">
                <div style="width: fit-content">PTZ Movement {{ ptzIncrement }}&#176;</div>
                <input type="range" class="custom-range" min="1" max="180" v-model="ptzIncrement">
            </div>
            <div v-if="ptzStatus.zoomLimitMax !== 0"
                 class="d-flex flex-column w-100 align-items-center" style="padding: 0 10px;">
                <div v-if="ptzStatus.zoomMoving === false" style="width: fit-content">Zoom {{ zoomPosition }}%</div>
                <div v-else style="width: fit-content">Zoom
                    <fa-icon :icon="['fas', 'circle-notch']" spin/>
                </div>
                <b-input type="range" class="custom-range" @change="zoom()" :min="0" :max="100" v-model="zoomPosition"/>
            </div>

            <div v-if="supportsPTZ()" style="margin: 10px 0; height: auto;">
                <div class="d-flex flex-row" style="margin: 15px 5px;">
                    <b-input-group size="sm">
                        <b-form-select :options="presets" v-model="selectedPreset">
                            <template #first>
                                <b-form-select-option :value="null" disabled>Select a Preset</b-form-select-option>
                            </template>
                        </b-form-select>
                        <b-input-group-append>
                            <b-button :disabled="selectedPreset === null" @click="goToPreset()" variant="primary">
                                Go To Preset
                            </b-button>
                        </b-input-group-append>
                    </b-input-group>
                </div>
                <div class="d-flex flex-row" style="margin: 5px 5px 15px 5px">
                    <b-input-group size="sm">
                        <b-input type="text" v-model="presetName" placeholder="Enter a Preset Name"></b-input>
                        <b-input-group-append>
                            <b-button :disabled="presetName === null || presetName === ''" @click="setPreset()"
                                      variant="primary" size="sm">
                                Save Preset
                            </b-button>
                        </b-input-group-append>
                    </b-input-group>
                </div>
                <div class="d-flex flex-row flex-wrap" style="justify-content: center">
                    <b-button @click="goToHome()" size="sm" style="margin: 5px;" variant="info">Go To Home
                    </b-button>
                    <b-button @click="resetHome()" size="sm" style="margin: 5px;" variant="info">Set Home
                    </b-button>
                    <b-button :disabled="selectedPreset === null" @click="removePreset()" variant="danger" size="sm"
                              style="margin: 5px;">
                        Remove Preset
                    </b-button>
                </div>
            </div>
        </div>
    </div>

</template>

<script>

export default {
    name: "GammaPTZControl",
    props: {
        device: {
            type: Object,
        },
        sourceToken: {
            type: String,
            default: '0'
        },
        dataChannel: {
            type: String,
            default: undefined
        }
    },
    data: () => {
        return {
            ptzStatus: null,
            ptzIncrement: 5,
            zoomPosition: 0,
            presets: [],
            selectedPreset: null,
            presetName: null,
            ptzBool: false
        }
    },
    async created() {
        await this.device.createPTZhub(this.device, this.sourceToken, this.dataChannel);
        await this.device.connectToPTZhub(this.sourceId);
        this.device.setPTZStatusListener(this.sourceId, event => {
            if (event !== null) {
                this.ptzStatus = Object.assign({}, event);
                this.zoomPosition = Math.floor((event.zoomPositionX / event.zoomLimitMax) * 100);
                this.presets = event.presets.map((preset, index) => {
                    return {text: preset.presetName, value: index};
                });
            }
        });
        this.device.addPTZSourceListener(this.sourceId);
    },
    methods: {
        setClickPTZ(boolean) {
            this.device.setClickPTZ(boolean);
            this.ptzBool = boolean;
            this.device.resetVideoSize(this.sourceId, true);
        },
        goToPreset() {
            if (this.selectedPreset !== null) {
                this.absoluteMove(this.ptzStatus.presets[this.selectedPreset].panTiltXSetting, this.ptzStatus.presets[this.selectedPreset].panTiltYSetting);
            }
        },
        goToHome() {
            this.device.PTZGoToHome(this.sourceId);
        },
        resetHome() {
            this.device.PTZResetHome(this.sourceId);
        },
        removePreset() {
            if (this.selectedPreset !== null) {
                this.device.PTZRemovePreset(this.sourceId, this.ptzStatus.presets[this.selectedPreset].presetName);
                this.selectedPreset = null;
            }
        },
        setPreset() {
            if (this.presetName !== null) {
                this.device.PTZSetPreset(this.sourceId, this.presetName);
                this.presetName = null;
            }
        },
        checkIsPlayingBack() {
            if (this.device.isLive(this.sourceId) === false) {
                this.device.setClickPTZ(false);
                this.ptzBool = false;
            }
            return !this.device.isLive(this.sourceId);
        },
        absoluteMove(x, y) {
            this.device.PTZAbsoluteMove(this.sourceId, x, y);
        },
        panTilt(movement) {
            let newPosX = 0, newPosY = 0;
            switch(movement) {
                case 0:
                    newPosY += ((this.ptzStatus.panTiltYLimitMax / 360) * this.ptzIncrement);
                    break;
                case 1:
                    newPosX -= ((this.ptzStatus.panTiltXLimitMax / 360) * this.ptzIncrement);
                    break;
                case 2:
                    newPosX += ((this.ptzStatus.panTiltXLimitMax / 360) * this.ptzIncrement);
                    break;
                case 3:
                    newPosY -= ((this.ptzStatus.panTiltYLimitMax / 360) * this.ptzIncrement);
                    break;
            }
            this.device.PTZPanTilt(this.sourceId, newPosX, newPosY);
        },
        zoom() {
            this.ptzStatus.zoomMoving = true;
            let newPos = ((this.zoomPosition / 100) * this.ptzStatus.zoomLimitMax) - this.ptzStatus.zoomPositionX;
            this.device.PTZZoom(this.sourceId, newPos);
        },
        supportsPTZ() {
            return (this.ptzStatus.panTiltXLimitMax !== 0 || this.ptzStatus.panTiltXLimitMin !== 0 || this.ptzStatus.panTiltYLimitMax !== 0 || this.ptzStatus.panTiltYLimitMin !== 0);
        }
    },
    computed: {
        sourceId() {
            return this.device.getDeviceId() + this.sourceToken + this.dataChannel;
        }
    }
}
</script>

<style scoped>

</style>