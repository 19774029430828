import { render, staticRenderFns } from "./SidebarNavLink.vue?vue&type=template&id=0850fa76&scoped=true"
import script from "./SidebarNavLink.vue?vue&type=script&lang=js"
export * from "./SidebarNavLink.vue?vue&type=script&lang=js"
import style0 from "./SidebarNavLink.vue?vue&type=style&index=0&id=0850fa76&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0850fa76",
  null
  
)

export default component.exports